var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout",
    { attrs: { id: "components-layout-demo-responsive" } },
    [
      _c("TheSidebar"),
      _c(
        "a-layout",
        { style: { marginLeft: _vm.isSidebarShow ? "0" : "25rem" } },
        [
          _c(
            "a-layout-header",
            { staticClass: "nav", class: { "nav-show": _vm.isSidebarShow } },
            [_c("TheHeader")],
            1
          ),
          _c(
            "a-layout-content",
            [
              _vm.$route.meta.breadcrumb
                ? _c(
                    "a-breadcrumb",
                    { staticClass: "mb-3" },
                    _vm._l(_vm.$route.meta.breadcrumb, function(data, index) {
                      return _c(
                        "a-breadcrumb-item",
                        { key: index, attrs: { href: data.href } },
                        [
                          data.icon
                            ? _c("a-icon", { attrs: { type: data.icon } })
                            : _vm._e(),
                          data.href
                            ? _c(
                                "router-link",
                                { attrs: { to: "" + data.href } },
                                [_vm._v(" " + _vm._s(_vm.$t(data.name)) + " ")]
                              )
                            : _c("span", [
                                _vm._v(" " + _vm._s(_vm.$t(data.name)) + " ")
                              ])
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e(),
              _c(
                "transition",
                { attrs: { name: "fade", mode: "out-in" } },
                [_c("router-view", { key: _vm.$route.path })],
                1
              )
            ],
            1
          ),
          _c("TheFooter")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }