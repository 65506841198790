var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout-sider",
    {
      staticClass: "background-image",
      class: _vm.dynamicBackground,
      style: { overflow: "auto", height: "100vh", position: "fixed", left: 0 },
      attrs: {
        width: "25rem",
        theme: "light",
        "collapsed-width": 0,
        trigger: null,
        collapsible: ""
      },
      model: {
        value: _vm.minimize,
        callback: function($$v) {
          _vm.minimize = $$v
        },
        expression: "minimize"
      }
    },
    [
      _c("figure", { staticClass: "logo-container" }, [
        _c("img", {
          staticClass: "img-fluid",
          attrs: {
            loading: "lazy",
            src: require("../assets/image/logo_gpw.png"),
            alt: "Logo Global Pratama Wijaya",
            width: "60%",
            height: "auto"
          }
        })
      ]),
      _c(
        "a-menu",
        {
          attrs: {
            mode: "inline",
            "inline-collapsed": _vm.collapsed,
            theme: "light"
          }
        },
        [
          _vm._l(_vm.listNav, function(menu1) {
            return [
              !menu1.isHeader
                ? [
                    menu1.show
                      ? _c(
                          "a-menu-item",
                          { key: menu1.key },
                          [
                            _c(
                              "router-link",
                              { attrs: { to: menu1.route } },
                              [
                                _c("a-icon", { attrs: { type: menu1.icon } }),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t(menu1.title)))
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                : [
                    menu1.show
                      ? _c(
                          "a-sub-menu",
                          { key: "sub" + menu1.key },
                          [
                            _c(
                              "span",
                              { attrs: { slot: "title" }, slot: "title" },
                              [
                                _c("a-icon", { attrs: { type: menu1.icon } }),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t(menu1.title)))
                                ])
                              ],
                              1
                            ),
                            _vm._l(menu1.children, function(menu2) {
                              return [
                                !menu2.isHeader
                                  ? [
                                      menu2.show
                                        ? _c(
                                            "a-menu-item",
                                            { key: menu2.key },
                                            [
                                              _c(
                                                "router-link",
                                                { attrs: { to: menu2.route } },
                                                [
                                                  _c("a-icon", {
                                                    attrs: { type: menu2.icon }
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(menu2.title)
                                                      ) +
                                                      " "
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  : [
                                      menu2.show
                                        ? _c(
                                            "a-sub-menu",
                                            { key: "deep" + menu2.key },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  attrs: { slot: "title" },
                                                  slot: "title"
                                                },
                                                [
                                                  _c("a-icon", {
                                                    attrs: { type: menu2.icon }
                                                  }),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(menu2.title)
                                                      )
                                                    )
                                                  ])
                                                ],
                                                1
                                              ),
                                              _vm._l(menu2.children, function(
                                                menu3
                                              ) {
                                                return [
                                                  !menu3.isHeader
                                                    ? [
                                                        menu3.show
                                                          ? _c(
                                                              "a-menu-item",
                                                              {
                                                                key: menu3.key
                                                              },
                                                              [
                                                                _c(
                                                                  "router-link",
                                                                  {
                                                                    attrs: {
                                                                      to:
                                                                        menu3.route
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "a-icon",
                                                                      {
                                                                        attrs: {
                                                                          type:
                                                                            menu3.icon
                                                                        }
                                                                      }
                                                                    ),
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            menu3.title
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    : [
                                                        menu3.show
                                                          ? _c(
                                                              "a-sub-menu",
                                                              {
                                                                key:
                                                                  "deep" +
                                                                  menu3.key
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    attrs: {
                                                                      slot:
                                                                        "title"
                                                                    },
                                                                    slot:
                                                                      "title"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "a-icon",
                                                                      {
                                                                        attrs: {
                                                                          type:
                                                                            menu3.icon
                                                                        }
                                                                      }
                                                                    ),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            menu3.title
                                                                          )
                                                                        )
                                                                      )
                                                                    ])
                                                                  ],
                                                                  1
                                                                ),
                                                                _vm._l(
                                                                  menu3.children,
                                                                  function(
                                                                    menu4
                                                                  ) {
                                                                    return [
                                                                      !menu4.isHeader
                                                                        ? [
                                                                            menu4.show
                                                                              ? _c(
                                                                                  "a-menu-item",
                                                                                  {
                                                                                    key:
                                                                                      menu4.key
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "router-link",
                                                                                      {
                                                                                        attrs: {
                                                                                          to:
                                                                                            menu4.route
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "a-icon",
                                                                                          {
                                                                                            attrs: {
                                                                                              type:
                                                                                                menu4.icon
                                                                                            }
                                                                                          }
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                menu4.title
                                                                                              )
                                                                                            ) +
                                                                                            " "
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                        : [
                                                                            menu4.show
                                                                              ? _c(
                                                                                  "a-sub-menu",
                                                                                  {
                                                                                    key:
                                                                                      "deep" +
                                                                                      menu4.key
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      {
                                                                                        attrs: {
                                                                                          slot:
                                                                                            "title"
                                                                                        },
                                                                                        slot:
                                                                                          "title"
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "a-icon",
                                                                                          {
                                                                                            attrs: {
                                                                                              type:
                                                                                                menu4.icon
                                                                                            }
                                                                                          }
                                                                                        ),
                                                                                        _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  menu4.title
                                                                                                )
                                                                                              )
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _vm._l(
                                                                                      menu4.children,
                                                                                      function(
                                                                                        menu5
                                                                                      ) {
                                                                                        return [
                                                                                          !menu5.isHeader
                                                                                            ? [
                                                                                                menu5.show
                                                                                                  ? _c(
                                                                                                      "a-menu-item",
                                                                                                      {
                                                                                                        key:
                                                                                                          menu5.key
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "router-link",
                                                                                                          {
                                                                                                            attrs: {
                                                                                                              to:
                                                                                                                menu5.route
                                                                                                            }
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "a-icon",
                                                                                                              {
                                                                                                                attrs: {
                                                                                                                  type:
                                                                                                                    menu5.icon
                                                                                                                }
                                                                                                              }
                                                                                                            ),
                                                                                                            _vm._v(
                                                                                                              " " +
                                                                                                                _vm._s(
                                                                                                                  _vm.$t(
                                                                                                                    menu5.title
                                                                                                                  )
                                                                                                                ) +
                                                                                                                " "
                                                                                                            )
                                                                                                          ],
                                                                                                          1
                                                                                                        )
                                                                                                      ],
                                                                                                      1
                                                                                                    )
                                                                                                  : _vm._e()
                                                                                              ]
                                                                                            : _vm._e()
                                                                                        ]
                                                                                      }
                                                                                    )
                                                                                  ],
                                                                                  2
                                                                                )
                                                                              : _vm._e()
                                                                          ]
                                                                    ]
                                                                  }
                                                                )
                                                              ],
                                                              2
                                                            )
                                                          : _vm._e()
                                                      ]
                                                ]
                                              })
                                            ],
                                            2
                                          )
                                        : _vm._e()
                                    ]
                              ]
                            })
                          ],
                          2
                        )
                      : _vm._e()
                  ]
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }