// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/image/wave_sidebar.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/image/wave_sidebar-sta.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/image/wave_sidebar-dev.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "[data-v-2534a5df]::-webkit-scrollbar {\n  width: 8px;\n}\n[data-v-2534a5df]::-webkit-scrollbar-thumb {\n  border-radius: 8px;\n  background: #e4e4e4;\n}\n[data-v-2534a5df]::-webkit-scrollbar-track {\n  background: transparent;\n  border-radius: 20px;\n  width: 2px;\n}\n[data-v-2534a5df]::-webkit-scrollbar-thumb:hover {\n  background: #bdbdbd;\n}\n[data-v-2534a5df]::-webkit-scrollbar-track:hover {\n  background: #e7e2e2;\n  border: none;\n}\n.logo-container[data-v-2534a5df] {\n  width: 100%;\n  margin: 0 auto;\n  padding: 1rem 0;\n  text-align: center;\n}\n.background-image[data-v-2534a5df] {\n  background-attachment: fixed;\n  background-position: bottom;\n  background-repeat: no-repeat;\n}\n.background-image-prod[data-v-2534a5df] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.background-image-sta[data-v-2534a5df] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.background-image-dev[data-v-2534a5df] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n", ""]);
// Exports
module.exports = exports;
