















































import TheSidebar from "./TheSidebar.vue";
import TheHeader from "./TheHeader.vue";
import TheFooter from "./TheFooter.vue";
import Vue from "vue";

export default Vue.extend({
  name: "TheContainer",
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
  },
  data(){
    return{
      datalistBreadCrumb : [],
    };
  },
  computed: {
    isSidebarShow() {
      return this.$store.state.sidebarMinimize;
    }
  },
});
