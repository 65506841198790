














import {version} from "../../package.json";
import Vue from "vue";
import moment from "moment";
export default Vue.extend({
  name: "TheFooter",
  data: () => ({
    appVersion:version,
    currentEnv: process.env.NODE_ENV
  }),
  computed: {
    currentYear(): string {
      return moment().year().toString();
    }
  },
});
