
























































































































































import Vue from "vue";
import router from "@/router";
import { INavMenu } from "@/models/interface-v2/navigation.interface";
import { mapGetters, mapState } from "vuex";

export default Vue.extend({
  name: "TheSidebar",
  data() {
    return {
      collapsed: false,
    };
  },
  computed: {
    ...mapState({
      listNav: (vuex: any) => vuex.listMenu as INavMenu[],
    }),
    ...mapGetters({
      getUserRoleAndAdditionalMenu: "authStore/GET_USER_ROLE_AND_ADDITIONAL_MENU",
    }),
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
    dynamicBackground() {
      return process.env.NODE_ENV === "production" ? "background-image-prod" : process.env.NODE_ENV === "staging" ? "background-image-sta" : "background-image-dev";
    },
  },
  watch: {
    getUserRoleAndAdditionalMenu(newVal): void {
      if (newVal && newVal.length) this.initMenuV2();
    },
  },
  created() {
    this.initMenuV2();
  },
  methods: {
    onclick(route: string): void {
      router.push(route);
    },
    /**
     * init list menu
     * compare to user privilege
     * only capable to 3 Level of
     * nested menu
     */
    initMenuV2(): void {
      const userRoleAndAdditionalMenu = this.getUserRoleAndAdditionalMenu as string[];
      // const roles = ["Warehouse"] ;
      // do list menu iteration
      this.listNav.forEach(menu => {
        // find menu with roles
        if (menu.roles.length) {
          // do roles iteration
          userRoleAndAdditionalMenu.forEach(priv => {
            // if user privilege is match
            // or match with additional menu
            // then show
            if (menu.roles.includes(priv) || menu.menus.includes(priv)) {
              menu.show = true;
              
              // check if this menu has children menu
              if (menu.children.length) {
                // do nested iteration lvl 2
                // with child menu
                menu.children.forEach(sub => {
                  // compare privileges sub menu with user privilege
                  if (sub.roles.includes(priv) || sub.menus.includes(priv)) {
                    sub.show = true;
                  }

                  // do iteration lvl 3 for
                  // nested child menu
                  if (sub.children.length) {
                    // compare privileges nested sub menu
                    // with user privilege
                    sub.children.forEach(nsub => {
                      // if match then show
                      if (nsub.roles.includes(priv) || nsub.menus.includes(priv)) {
                        nsub.show = true;
                      }

                      // do iteration lvl 4 for
                      // nested child menu
                      if (nsub.children.length) {
                        // compare privileges nested sub menu
                        // with user privilege
                        nsub.children.forEach((msub) => {
                          // if match then show
                          if (msub.roles.includes(priv) || msub.menus.includes(priv)) {
                            msub.show = true;
                          }
                          // do iteration lvl 5 for
                          // nested child menu
                          if (msub.children.length) {
                            // compare privileges nested sub menu
                            // with user privilege
                            msub.children.forEach((osub) => {
                              // if match then show
                              if (osub.roles.includes(priv) || osub.menus.includes(priv)) {
                                osub.show = true;
                              }
                            });
                          }
                        });
                      }
                    });
                  }

                });
              }
            }
          });
        }
      });
    },
  },
});
