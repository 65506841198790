
















































import Vue from "vue";
import { Mutations } from "@/models/enums/mutations.enum";
import { debounceProcess } from "@/helpers/debounce";
import store from "@/store";
import TheHeaderDropdownAccnt from "./TheHeaderDropdownAccnt.vue";
import LocalStorageService from "@/services/localStorage.service";
import { Locales } from "@/models/enums/global.enum";

export default Vue.extend({
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt
  },
  data() {
    return {
      defaultLang: LocalStorageService.getCurrentLang() || Locales.ENGLISH
    };
  },
  mounted () {
    window.addEventListener("resize",(e: UIEvent) => {
      const target = e.target as Window;
      this.setInnerWidth(target?.innerWidth);
      this.setInnerHeight(target?.innerHeight);
    });
  },
  methods: {
    setInnerWidth: debounceProcess((nv) => {
      store.commit(Mutations.SET_INNERWIDTH, nv);
    }, 200),
    setInnerHeight: debounceProcess((nv) => {
      store.commit(Mutations.SET_INNERHEIGHT, nv);
    }, 200),
    toggle() {
      const state = store.state.sidebarMinimize;
      if (state) {
        store.commit("set", ["sidebarMinimize", false]);
      } else {
        store.commit("set", ["sidebarMinimize", true]);
      }
    },
    handleChange(value: string): void {
      this.$root.$i18n.locale = value;
      LocalStorageService.setCurrentLang(value);
    }
  },
});
