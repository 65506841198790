




















import MNotificationVue from "@/mixins/MNotification.vue";
import { DECIMAL_PLACES_CURRENCY } from "@/models/constant/global.constant";
import { PREFERENCE_FEATURE_KEY } from "@/models/enums/preference.enum";
import localStorageService from "@/services/localStorage.service";
import { settingsServices } from "@/services/settings.service";
import { APP_DECIMAL_PLACES } from "@enum/global.enum";
import Vue from "vue";
import { createNamespacedHelpers, mapMutations, mapState } from "vuex";

const { mapGetters } = createNamespacedHelpers("authStore");

export default Vue.extend({
  name: "TheHeaderDropdownAccnt",
  mixins: [
    MNotificationVue,
  ],
  data() {
    return {
      dataUser: this.$store.state.access_token,
      roles: [] as string[],
      username: "" as string,
      email: "" as string,
      nickName: "" as string,
    };
  },
  computed: {
    ...mapGetters({
      getUserRole: "GET_USER_ROLE"
    }),
    ...mapState({
      authStore: (st: any) => st.authStore
    })
  },
  created(): void {
    settingsServices.getPreference({})
    .then((res) => {
      this.$store.commit("preferenceStore/SET_APP_PREFERENCE", res);
      const featureDp = res.find(x => x.key === PREFERENCE_FEATURE_KEY.FEATURE_DECIMAL_POINT)?.value || DECIMAL_PLACES_CURRENCY;
      this.setAppDecimalPlaces(featureDp);
      localStorageService.save(APP_DECIMAL_PLACES.DP, featureDp.toString());
    })
    .catch(() => this.showErrorMessage("notif_process_fail"));
  },
  mounted() {
    this.getDetailUserByAuth();
  },
  methods: {
    ...mapMutations({
      setAppDecimalPlaces: "preferenceStore/SET_DECIMAL_PLACES",
    }),
    signOut(): void {
      this.$store.dispatch("ACTIONS_LOGOUT");
    },
    getDetailUserByAuth() {
      if (this.dataUser) {
          const [firstUsername, lastUsername] = this.authStore.authData.username.split("_");
          if (firstUsername) {
            this.username =
              firstUsername.charAt(0).toUpperCase() +
              firstUsername.slice(1) +
              " ";
            this.nickName = firstUsername.charAt(0).toUpperCase()[0] + " ";
          }
          if (lastUsername) {
            this.username +=
              lastUsername.charAt(0).toUpperCase() + lastUsername.slice(1);
            this.nickName += lastUsername.charAt(0).toUpperCase()[0];
          }
          this.email = this.authStore.authData.email;
          this.authStore.authData.roles.forEach((dataRole) => {
            if (dataRole.name) {
              this.roles.push(dataRole.name);
            }
          });
      }
    },
  },
});
