var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-layout-footer", { attrs: { theme: "dark" } }, [
    _c("div", [
      _c("span", [_vm._v("Global Pratama Wijaya")]),
      _c("span", { staticClass: "ml-1" }, [
        _vm._v("© " + _vm._s(_vm.currentYear) + " ")
      ]),
      _vm._v(
        " version : " +
          _vm._s(_vm.appVersion) +
          " - " +
          _vm._s(_vm.currentEnv) +
          " "
      )
    ]),
    _c("div", { staticClass: "mfs-auto" }, [
      _c("span", { staticClass: "mr-1" }, [_vm._v("Powered by")]),
      _c("span", [_vm._v("Nostra Technology")])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }