var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-row",
    {
      attrs: {
        gutter: [16, 16],
        type: "flex",
        justify: "space-around",
        align: "middle"
      }
    },
    [
      _c(
        "a-col",
        { attrs: { span: 12 } },
        [
          _c("a-button", {
            attrs: { icon: "menu", type: "primary" },
            on: { click: _vm.toggle }
          })
        ],
        1
      ),
      _c(
        "a-col",
        { attrs: { span: 12, align: "end" } },
        [
          _c(
            "a-space",
            { attrs: { size: 25 } },
            [
              _c(
                "a-select",
                {
                  attrs: { "default-value": _vm.defaultLang },
                  on: { change: _vm.handleChange }
                },
                [
                  _c("a-select-option", { attrs: { value: "en" } }, [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: {
                        src: require("@/assets/image/flag_usa.png"),
                        alt: "Flag Language",
                        width: "22px",
                        height: "auto"
                      }
                    }),
                    _vm._v(" " + _vm._s(_vm.$t("lbl_english")) + " ")
                  ]),
                  _c("a-select-option", { attrs: { value: "id" } }, [
                    _c("img", {
                      staticClass: "img-fluid",
                      attrs: {
                        src: require("@/assets/image/flag_id.png"),
                        alt: "Flag Language",
                        width: "22px",
                        height: "auto"
                      }
                    }),
                    _vm._v(" " + _vm._s(_vm.$t("lbl_bahasa")) + " ")
                  ])
                ],
                1
              ),
              _c("TheHeaderDropdownAccnt")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }