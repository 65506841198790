var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-dropdown",
    { attrs: { trigger: ["click"] } },
    [
      _c(
        "a",
        {
          staticClass: "ant-dropdown-link",
          staticStyle: { color: "black" },
          on: {
            click: function(e) {
              return e.preventDefault()
            }
          }
        },
        [_c("a-icon", { attrs: { type: "user" } })],
        1
      ),
      _c(
        "a-menu",
        { attrs: { slot: "overlay" }, slot: "overlay" },
        [
          _c("Profile", {
            attrs: {
              email: _vm.email,
              roles: _vm.roles,
              username: _vm.username,
              "nick-name": _vm.nickName
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }